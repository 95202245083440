<template>
  <div class="text-center">
    <button
      color="black"
      @click="dialog = true"
      style="cursor: pointer; color: #000; padding: 13px"
    >
      Sign Up
    </button>

    <v-dialog
      v-model="dialog"
      :width="$vuetify.display.mdAndDown ? '100vw' : '50vw'"
    >
      <v-card
        style="
          padding: 20px;
          background-color: #1c1c1e;
          color: rgb(232, 252, 180);
        "
      >
        <h2 style="margin-bottom: 20px">Register</h2>
        <v-form
          @submit.prevent="createBranch"
          ref="form"
          v-model="isValid"
          class="pa-4 pt-6"
        >
          <div class="form">
            <div class="forms">
              <v-text-field
                v-model="name"
                density="compact"
                variant="outlined"
                label="Company Name"
                class="mb-2"
                :rules="[rules.length(2)]"
                required
              ></v-text-field>
            </div>
            <div class="forms">
              <v-text-field
                v-model="email"
                density="compact"
                variant="outlined"
                label="Email"
                class="mb-2"
                :rules="[rules.email]"
                required
              ></v-text-field>
            </div>

            <div class="forms">
              <v-textarea
                v-model="Description"
                density="compact"
                variant="outlined"
                label="Description"
                class="mb-2"
                required
                :rules="[rules.length(10)]"
              ></v-textarea>
            </div>
            <div class="form-botton">
              <v-btn
                :disabled="loading"
                @click="cancelRegistration"
                variant="text"
              >
                Cancel
              </v-btn>
              <v-btn :loading="loading" type="submit" class="btn-register"
                >Register</v-btn
              >
            </div>
          </div>
        </v-form>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import axiosInstance from "@/utils/axiosInstance";

export default {
  name: "RegisterDialog",
  data() {
    return {
      dialog: false,
      isValid: false,
      name: "",
      email: "",
      Description: "",
      loading: false,
      rules: {
        email: (v) => !!(v || "").match(/@/) || "Please enter a valid email",
        length: (len) => (v) =>
          (v || "").length >= len ||
          `Invalid character length, required ${len}`,
        password: (v) =>
          !!(v || "").match(
            /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*(_|[^\w])).+$/
          ) ||
          "Password must contain an upper case letter, a numeric character, and a special character",
        required: (v) => !!v || "This field is required",
      },
    };
  },
  methods: {
    async createBranch() {
      const { valid } = await this.$refs.form.validate();

      if (valid) {
        this.loading = true;
        const requestData = {
          name: this.name,
          description: this.Description,
          email: this.email,
        };

        try {
          // Make the POST request to the Django backend
          const response = await axiosInstance.post(
            "/requesting-users/",
            requestData
          );

          // Handle the response as needed (e.g., show success message)
          //console.log("Registration success:", response.data);
          this.$swal(
            "Thanks for signing up with Workout Scanner. Our team will contact you by email soon as possible",
            "",
            "success"
          );

          this.$refs.form.reset();
          // Close the dialog
          this.dialog = false;
        } catch (error) {
          // Handle any errors (e.g., show error message)
          console.error("Registration error:", error);
          this.$swal(`Registration error: ${error}`, "", "info");
        }
        this.loading = false;
      }
    },
    cancelRegistration() {
      // Clear the form and close the dialog without making a request
      this.$refs.form.reset();
      this.dialog = false;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../../scss/colors";
@import "@/scss/website-styles/_websitecolor.scss";
h2 {
  color: $primary-light-active;
}

.form-botton {
  display: flex;
  align-items: center;
  justify-content: right;
  gap: 15px;
}

.btn-register {
  background-color: $primary-normal;
  color: $secondary-normal;
}

button {
  font-weight: 600;
  border-radius: none;
  text-transform: capitalize;
  border-radius: 3px !important;
  padding: 0px 25px;

  background-color: $basic-green;

  @media (max-width: 600px) {
    font-size: 10px;
    padding: 10px 15px;
  }
  // padding: 15px 20px;
}

// padding: 15px 20px;
</style>
